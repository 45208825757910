import $
	from 'jquery';
import {
	ScrollTrigger
} from 'gsap/ScrollTrigger';

export const rawCSSVariableName = (variable) => '--' + variable;
export const rawCSSVariable = (variable, element = document.documentElement) => getComputedStyle(element).getPropertyValue(rawCSSVariableName(variable));

export const CSSVariablePrefix = rawCSSVariable('ste-variable-prefix');
export const CSSVariableName = (variable) => rawCSSVariableName(CSSVariablePrefix + variable);
export const CSSVariable = (variable, element = document.documentElement) => rawCSSVariable(CSSVariablePrefix + variable, element);

export const Events = {
	lazyload:   'lazyload.refresh',
	resize:     'resize',
	page:       {
		loaded:     'page.loaded',
		enter:      'page.enter',
		leave:      'page.leave',
		transition: 'page.transition',
	},
	transition: {
		start:   'transitionstart',
		end:     'transitionend',
		refresh: 'transition.refresh'
	},
	animation:  {
		start:   'animationstart',
		end:     'animationend',
		refresh: 'animation.refresh'
	}
};

export const EventsPromises = {
	transition: {
		start: (element) => new Promise((resolve, reject) => $(element).one(Events.transition.start, resolve)),
		end:   (element) => new Promise((resolve, reject) => $(element).one(Events.transition.end, resolve)),
	},
	animation:  {
		start: (element) => new Promise((resolve, reject) => $(element).one(Events.animation.start, resolve)),
		end:   (element) => new Promise((resolve, reject) => $(element).one(Events.animation.end, resolve)),
	}
};

export const Breakpoints = {
	xs:     parseInt(CSSVariable('breakpoint-xs'), 10),
	sm:     parseInt(CSSVariable('breakpoint-sm'), 10),
	md:     parseInt(CSSVariable('breakpoint-md'), 10),
	lg:     parseInt(CSSVariable('breakpoint-lg'), 10),
	xl:     parseInt(CSSVariable('breakpoint-xl'), 10),
	xxl:    parseInt(CSSVariable('breakpoint-xxl'), 10),
	mobile: parseInt(CSSVariable('breakpoint-mobile'), 10),
	menu:   parseInt(CSSVariable('breakpoint-menu'), 10),
};

export const Classes = {
	LoadingBar:      'loading-bar',
	LoadingOverlay:  'loading-overlay',
	MainWrapper:     'main-wrapper',
	PageWrapper:     'page-wrapper',
	MenuWrapper:     'menu-wrapper',
	HeaderWrapper:   'header-wrapper',
	ContentWrapper:  'content-wrapper',
	FooterWrapper:   'footer-wrapper',
	active:          'active',
	leaving:         'leaving',
	loading:         'loading',
	loaded:          'loaded',
	initialLoad:     'initial-load',
	hidden:          'd-none',
	invisible:       'visually-hidden',
	transitionClone: 'transition-clone',
	moving:          'moving',
	introducing:     'introducing',
	animating:       'animating',
	show:            'show',
	menuOpen:        'menu-open',
	menuLight:       'menu-light',
	menuTransition:  'menu-transition',
	shrink:          'shrink',
	shrinkable:      'shrinkable',
	fullscreen:      'fullscreen',
};

export const Elements = {
	get LoadingBar() {
		return document.querySelector('.' + Classes.LoadingBar);
	},
	get LoadingOverlay() {
		return document.querySelector('.' + Classes.LoadingOverlay);
	},
	get MainWrapper() {
		return document.querySelector('.' + Classes.MainWrapper);
	},
	get PageWrapper() {
		return document.querySelector('.' + Classes.PageWrapper);
	},
	get MenuWrapper() {
		return document.querySelector('.' + Classes.MenuWrapper);
	},
	get HeaderWrapper() {
		return document.querySelector('.' + Classes.HeaderWrapper);
	},
	get ContentWrapper() {
		return document.querySelector('.' + Classes.ContentWrapper);
	},
	get FooterWrapper() {
		return document.querySelector('.' + Classes.FooterWrapper);
	},
};

export const $Elements = {
	get window() {
		return $(window);
	},
	get document() {
		return $(document);
	},
	get html() {
		return $(document.documentElement);
	},
	get body() {
		return $(document.body);
	},
	get LoadingBar() {
		return $(Elements.LoadingBar);
	},
	get LoadingOverlay() {
		return $(Elements.LoadingOverlay);
	},
	get MainWrapper() {
		return $(Elements.MainWrapper);
	},
	get PageWrapper() {
		return $(Elements.PageWrapper);
	},
	get MenuWrapper() {
		return $(Elements.MenuWrapper);
	},
	get HeaderWrapper() {
		return $(Elements.HeaderWrapper);
	},
	get ContentWrapper() {
		return $(Elements.ContentWrapper);
	},
	get FooterWrapper() {
		return $(Elements.FooterWrapper);
	},
};

export const Durations = {
	default:    {
		ms: parseInt(CSSVariable('transition-duration'), 10),
		s:  parseInt(CSSVariable('transition-duration'), 10) / 1000,
	},
	page:       {
		ms: parseInt(CSSVariable('page-transition-duration'), 10),
		s:  parseInt(CSSVariable('page-transition-duration'), 10) / 1000,
	},
	animations: {
		ms: parseInt(CSSVariable('animations-duration'), 10),
		s:  parseInt(CSSVariable('animations-duration'), 10) / 1000,
	},
	menu:       {
		ms: parseInt(CSSVariable('menu-transition-duration'), 10),
		s:  parseInt(CSSVariable('menu-transition-duration'), 10) / 1000,
	},
};

export const BarbaLinks = 'a:not([target="_blank"]):not([href*="#"]):not([href^="mailto:"]):not([href^="tel:"]):not(.void-link):not([download]):not([href=""]):not([hreflang]):not([target="lightbox"]):not([target^="lightbox-gallery"]):not([target="scroll-to"]):not([data-scroll-to]):not([href*="shop"]:not([href$="shop/"])):not([href*="boutique"]:not([href$="boutique/"]))';

export const ShopLinks = 'a[hreflang],a[href*="shop"]:not([href$="shop/"]),a[href*="boutique"]:not([href$="boutique/"])';

export const BaseLinks = 'a:not([target="_blank"]):not([href*="#"]):not([href^="mailto:"]):not([href^="tel:"]):not(.void-link):not([download]):not([href=""]):not([target="lightbox"]):not([target^="lightbox-gallery"]):not([target="scroll-to"]):not([data-scroll-to])';

export const MapTiles = {
	url:     'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
	options: {
		attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
		subdomains:  'abcd'
	}
};

export let Animations = null;

export const pauseAnimations = () => {
	ScrollTrigger.getAll().forEach(animation => animation.disable(false));
};

export const resetAnimations = () => {
	ScrollTrigger.getAll().forEach(animation => animation.kill());
	Animations = { global: [] };
	Object.entries(Breakpoints).forEach(([breakpoint, value]) => Animations[breakpoint] = { width: value, min: [], max: [] });
};

resetAnimations();

export const SmoothScroll = false;
export const ScrollableAttribute = 'data-scrollable';
export const ScrolledAttribute = 'data-scrolled';
