import $
	from 'jquery';
import GLightbox
	from 'glightbox';

import {
	$Elements,
	Classes,
	Events
} from './constants';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Fix Checkout ----------

	if($Elements.body.hasClass('woocommerce-checkout')) {
		$Elements.document.on('change', '#billing_postcode, #billing_city, #billing_country', () => $Elements.body.trigger('update_checkout', { update_shipping_method: true }));
	}

	// ---------- Ajax cart ----------

	$Elements.body.on('adding_to_cart', function(e, button, cart) {

		$Elements.LoadingBar.addClass(Classes.loading);

		$Elements.body.one('added_to_cart', () => {

			$Elements.LoadingBar.removeClass(Classes.loading);

			if(typeof ste_wc_added_to_cart !== 'undefined') {

				const cartModal = new GLightbox({
					skin:        'woocommerce',
					draggable:   false,
					zoomable:    false,
					closeButton: false,
					openEffect:  'fade',
					closeEffect: 'fade',
					elements:    [{ content: ste_wc_added_to_cart.content }],
				});

				cartModal.on('open', () => $(cartModal.modal).find('[data-close]').on('click', () => cartModal.close()));
				cartModal.open();
			}
		});
	});

	// ---------- Checkout Login Form ----------

	if(typeof ste_wc_checkout_login_form !== 'undefined') {

		$('.show-checkout-login-form').on('click', function() {

			const loginModal = new GLightbox({
				skin:        'woocommerce',
				draggable:   false,
				zoomable:    false,
				closeButton: false,
				openEffect:  'fade',
				closeEffect: 'fade',
				elements:    [{ content: ste_wc_checkout_login_form.content }],
			});

			loginModal.on('open', () => {

				const user_email = $('input#billing_email').val();

				if(user_email.length) {
					$(loginModal.modal).find('input#username').val(user_email);
				}

				$(loginModal.modal).find('[data-close]').on('click', () => loginModal.close());
			});

			loginModal.open();
		});
	}
});
