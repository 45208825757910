//======================================
//	!---------- MAIN SCRIPT ----------
//======================================

// ---------- Imports ----------
import 'jquery';
import 'bootstrap/dist/js/bootstrap.esm.js';
import 'parsleyjs';

// ---------- Page Transitions ----------
//import './transitions/barba';
import { initPageTransition } from './transitions/default';
import { BaseLinks } from './global/constants';

initPageTransition(BaseLinks);

// ---------- Animations ----------
import './animations/scroll';
import './animations/base';

// ---------- Global ----------
import './global/global';
import './global/menu';
import './global/sections';
import './global/maps';
import './global/woocommerce';

// ---------- Ajax ----------
import './ajax/ajax-posts';
import './ajax/forms';
//import './ajax/media-library';
//import './ajax/newsletter';
//import './ajax/store-locator';
